/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkoutDetail = /* GraphQL */ `
  query GetWorkoutDetail($id: ID!) {
    getWorkoutDetail(id: $id) {
      id
      workoutName
      containsDumbbell
      containsKettlebell
      containsPull
      workoutDetail
      uploadedBy
      lowerBody
      upperBody
      fullBody
      difficulty
      detail
      createdAt
      updatedAt
    }
  }
`;
export const listWorkoutDetails = /* GraphQL */ `
  query ListWorkoutDetails(
    $filter: ModelWorkoutDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkoutDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workoutName
        containsDumbbell
        containsKettlebell
        containsPull
        workoutDetail
        uploadedBy
        lowerBody
        upperBody
        fullBody
        difficulty
        detail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
