import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import ProductDetail from './components/productDetail/ProductDetail';
import Nav_bar from './components/navbar/Navbar';
import App from './App';

Amplify.configure(awsExports);

const Routing = () => {
  return (
    <Router>
      <Nav_bar />
      <Routes>
        <Route path="/" exact element={<App />}></Route>
        <Route path="/product/:id" element={<ProductDetail />} />
      </Routes>
    </Router>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);
