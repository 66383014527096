import React, { useState, useEffect } from 'react';
import './table.css';
import Table from 'react-bootstrap/Table'; 
import { format } from "date-fns";

function WorkoutTable({dataParentToChild}) {
    
    
    function renderTableData() {
        return dataParentToChild.map((workout, index) => {
           const { id, workoutName, difficulty, updatedAt } = workout
           var date = new Date(updatedAt);
           var formattedDate = format(date, "do MMMM, yy");
           return (
              <tr key={id}>
                 <td><a href={"/product/" + id}>{workoutName}</a></td>
                 <td>{difficulty}</td>
                 <td>{formattedDate}</td>
              </tr>
           )
        })
     };

    return (
        <div className="mainTableContainer">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Workout Name</th>
                        <th>Difficulty</th>
                        <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableData()}
                </tbody>
            </Table>
        </div>
    )
}

export default WorkoutTable;
