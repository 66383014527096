import React from 'react';
import Alert from 'react-bootstrap/Alert';
import './Overview.css';

function Overview({dataParentToChild}) {
    return (
        <div className="mainContainerOverview">
        <Alert variant="success">
            <Alert.Heading>Heyfever, use the below form to add new workouts.</Alert.Heading>
            <p>
                Currently there are {dataParentToChild} workouts loaded. Once you add a workout
                it will appear in the table below.
            </p>
            <hr />
            <p className="mb-0">
                Click the name of a workout below to edit or delete.
            </p>
        </Alert>
        </div>
    )
}

export default Overview
