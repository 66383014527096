import React from 'react';
import './InputForm.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { createWorkoutDetail } from '../../graphql/mutations';
import { API, graphqlOperation } from "aws-amplify";
import RichText from './RichText';

export default class FormSubmission extends React.Component {
    constructor(props) {
        super(props);
        this.setStateOfParent.bind(this);

        this.state = {
            workoutName: "",
            switchDumbbell: false,
            switchKettlebell: false,
            switchPull: false,
            workoutDetail: "",
            uploadedBy: "lee",
            switchLower: false,
            switchUpper: false,
            switchFull: false,
            difficulty: 0,
            detail: "",
            buttonText: "Update Workout",
            buttonState: "",
            buttonVarient: "primary"
        }
    }

    handleInputChanged(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    }

    handleSwitchChange = name => () => {
        let switchName = `switch${name}`;
        this.setState({
            [switchName]: !this.state[switchName]
        });
    }

    upload = async () => {
        try {
            let regester = {
                workoutName: this.state.workoutName,
                containsDumbbell: this.state.switchDumbbell,
                containsKettlebell: this.state.switchKettlebell,
                containsPull: this.state.switchPull,
                workoutDetail: this.state.detail,
                uploadedBy: this.state.uploadedBy,
                lowerBody: this.state.switchLower,
                upperBody: this.state.switchUpper,
                fullBody: this.state.switchFull,
                difficulty: this.state.difficulty
            };
            await API.graphql(graphqlOperation(createWorkoutDetail, { input: regester }));
            console.log('API Load complete!');
            this.setState({buttonVarient: "success", buttonText: "Success"})
            window.location.reload(false);
        } catch (e) {
            console.log('Error creating interest with API');
            var errorMessage = e.errors[0].errorType
            this.setState({buttonVarient: "danger", buttonText: "Error, " + errorMessage, buttonState: ""})
        }
    };

    cleanForm() {
        this.setState({switchFull: false, switchUpper: false, switchLower: false, workoutName: "", switchDumbbell: false, switchKettlebell: false, switchPull: false,workoutDetail: ""});
    }

    setStateOfParent(event) {
        this.setState({detail : event})
    }

    handleButtonClicked(event) {
        event.preventDefault();
        this.setState({buttonState: "disabled"})
        this.setState({buttonText: "Uploading..."})
        this.upload();
        
        // this.cleanForm();
        // fetchWorkouts();
    }

    

    render() {
        return (
            <>
            
            <div className="mainContainerNewInput">
                
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Workout</Form.Label>
                        <Form.Control type="text" placeholder="Enter the Workout Name" name="workoutName" value={this.state.workoutName} onChange={this.handleInputChanged.bind(this)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                            inline
                            type="switch"
                            id="custom-switch-dumbbell"
                            label="Dumbbell"
                            checked={this.state.switchDumbbell}
                            onChange={this.handleSwitchChange('Dumbbell')}
                        />
                        <Form.Check
                            inline
                            type="switch"
                            id="custom-switch-kettlebell"
                            label="Kettlebell"
                            checked={this.state.switchKettlebell}
                            onChange={this.handleSwitchChange('Kettlebell')}
                        />
                        <Form.Check
                            inline
                            type="switch"
                            id="custom-switch-pull"
                            label="Pull Up Bar"
                            checked={this.state.switchPull}
                            onChange={this.handleSwitchChange('Pull')}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                            inline
                            type="checkbox"
                            id="custom-switch-upper"
                            label="Upper Body"
                            checked={this.state.switchUpper}
                            onChange={this.handleSwitchChange('Upper')}
                        />
                        <Form.Check
                            inline
                            type="checkbox"
                            id="custom-switch-lower"
                            label="Lower Body"
                            checked={this.state.switchLower}
                            onChange={this.handleSwitchChange('Lower')}
                        />
                        <Form.Check
                            inline
                            type="checkbox"
                            id="custom-switch-full"
                            label="Full Body"
                            checked={this.state.switchFull}
                            onChange={this.handleSwitchChange('Full')}
                        />
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>Difficulty Level</Form.Label>
                    <Form.Select name="difficulty" onChange={this.handleInputChanged.bind(this)}>
                    <option>Select a level...</option>
                    <option value="1">Easy</option>
                    <option value="2">Intermediate</option>
                    <option value="3">Hard</option>
                    <option value="4">Pro!</option>
                    </Form.Select>
                    </Form.Group>
      <Form.Text className="text-muted">
                            Ensure you press Enter at the end of writting the workout detail below before clicking Upload. Otherwise the final character can be missed of the upload.
                        </Form.Text>
                    <RichText setStateOfParent = {this.setStateOfParent.bind(this)}/>
                    
                    <Button variant={this.state.buttonVarient} disabled={this.state.buttonState} type="submit" onClick={this.handleButtonClicked.bind(this)}>
                        {this.state.buttonText}
                    </Button>
                </Form>
            </div>
            </>
        );
    }
}