import React, { useState, useEffect } from 'react';
import './App.css';
import Nav_bar from './components/navbar/Navbar';
import Overview from './components/overview/Overview';
import NewInput from './components/inputForm/NewInput';
import WorkoutTable from './components/table/Table';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import '@aws-amplify/ui-react/styles.css';
import { withAuthenticator } from '@aws-amplify/ui-react';


function App({ signOut, user }) {
  
  const [workouts, setWorkouts] = useState([]);
  const [workoutNumber, setWorkoutNumber] = useState([]);

  const fetchWorkouts = async () => {
  try {
      const workoutData = await API.graphql({ query: queries.listWorkoutDetails });
      const workoutList = workoutData.data.listWorkoutDetails.items;
      const workoutCount = workoutList.length
      setWorkouts(workoutList);
      setWorkoutNumber(workoutCount);
  } catch (error) {
      console.log('error on fetching workouts', error);
  }
};

useEffect(() => {
  fetchWorkouts();
}, []);


  return (
    <>
    
    {/* <Nav_bar /> */}
    <Overview dataParentToChild = {workoutNumber}/>
    <NewInput />
    <WorkoutTable dataParentToChild = {workouts} />
    
    </>
  );
}

export default withAuthenticator(App);