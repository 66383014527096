import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../inputForm/InputForm.css';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as mutations from '../../graphql/mutations';
import { withAuthenticator } from '@aws-amplify/ui-react';

function ProductDetail() {

    const [userGroup, setUserGroup] = useState(false);

    useEffect(() => {
        try {
            Auth.currentAuthenticatedUser({
                bypassCache: false
            }).then(user => {
                var group = user.signInUserSession.accessToken.payload["cognito:groups"]
                group == 'admins' && setUserGroup(true)
            }).catch(err => console.log(err));
        }
        catch (e) {
            console.log(e);
        }
    }, []);

    const { id } = useParams();
    const [name, setName] = useState([]);
    const [kettle, setKettle] = useState([]);
    const [dumbbell, setDumbbell] = useState([]);
    const [pull, setPull] = useState([]);
    const [difficulty, setDifficulty] = useState([]);
    const [fullBody, setFullBody] = useState([]);
    const [lowerBody, setLowerBody] = useState([]);
    const [upperBody, setUpperBody] = useState([]);
    const [detail, setDetail] = useState([]);
    const [buttonText, setButtonText] = useState("Update Workout");
    const [buttonVarient, setButtonVarient] = useState("primary");
    const [buttonState, setButtonState] = useState("");
    const [buttonDelText, setButtonDelText] = useState("Delete :(");

    const fetchWorkout = async () => {
        try {
            const workoutData = await API.graphql({ query: queries.getWorkoutDetail, variables: { id: id } });

            setName(workoutData.data.getWorkoutDetail.workoutName)
            setKettle(workoutData.data.getWorkoutDetail.containsKettlebell)
            setDumbbell(workoutData.data.getWorkoutDetail.containsDumbbell)
            setPull(workoutData.data.getWorkoutDetail.containsPull)
            setDifficulty(workoutData.data.getWorkoutDetail.difficulty)
            setFullBody(workoutData.data.getWorkoutDetail.fullBody)
            setLowerBody(workoutData.data.getWorkoutDetail.lowerBody)
            setUpperBody(workoutData.data.getWorkoutDetail.upperBody)
            setDetail(workoutData.data.getWorkoutDetail.workoutDetail)

        } catch (error) {
            console.log('error on fetching workout', error);
        }
    };

    useEffect(() => {
        fetchWorkout();
    }, []);

    useEffect(() => {
        if (Object.keys(detail).length != 0) {
            updateEditor();
        }
    }, [detail])



    const updateEditor = () => {
        const blocksFromHtml = htmlToDraft(detail)
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        setEditorState(EditorState.createWithContent(contentState))
    }

    const handleNameInputChanged = (event) => {
        const value = event.target.value;
        setName(value);
    }

    const handleDiffInputChanged = (event) => {
        const value = event.target.value;
        setDifficulty(value);
    }



    const handleSwitchChangeDumbbell = () => {
        setDumbbell(prevCheck => !prevCheck)
    }
    const handleSwitchChangeKettle = () => {
        setKettle(prevCheck => !prevCheck)
    }
    const handleSwitchChangePull = () => {
        setPull(prevCheck => !prevCheck)
    }
    const handleSwitchChangeUpper = () => {
        setUpperBody(prevCheck => !prevCheck)
    }
    const handleSwitchChangeLower = () => {
        setLowerBody(prevCheck => !prevCheck)
    }
    const handleSwitchChangeFull = () => {
        setFullBody(prevCheck => !prevCheck)
    }
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [convertedContent, setConvertedContent] = useState([]);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }

    const handleButtonClicked = (event) => {
        event.preventDefault();
        setButtonText("Updating...")
        setButtonVarient("secondary")
        setButtonState("disabled")
        upload();
    }

    const handleDeleteClicked = async () => {
        setButtonState("disabled")
        setButtonDelText("Deleting...")
        try {
            let regester = {
                id: id
            }
            await API.graphql({ query: mutations.deleteWorkoutDetail, variables: { input: regester } });
            setButtonDelText("Done")
        } catch (error) {
            console.log("Error deleting item")
            setButtonDelText("Error!")
        }
    }

    const upload = async () => {
        try {
            let regester = {
                id: id,
                workoutName: name,
                containsDumbbell: dumbbell,
                containsKettlebell: kettle,
                containsPull: pull,
                workoutDetail: convertedContent,
                lowerBody: lowerBody,
                upperBody: upperBody,
                fullBody: fullBody,
                difficulty: difficulty
            };
            await API.graphql({ query: mutations.updateWorkoutDetail, variables: { input: regester } })
            setButtonText("Success")
            setButtonVarient("success")
        } catch (error) {
            var errorMessage = error.errors[0].errorType
            setButtonText("Error!" + errorMessage)
            setButtonVarient("danger")
        }
    };

    useEffect(() => {
        if (Object.keys(editorState).length != 0) {
            convertContentToHTML()
        }
    }, [editorState])


    if (userGroup) {
        return (
            <>
                <div className="mainContainerNewInput">
                    <div className="headline">
                        Use the form below to edit or delete a Workout.
                    </div>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Workout</Form.Label>
                            <Form.Control type="text" placeholder="Enter the Workout Name" name="workoutName" value={name} onChange={handleNameInputChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check
                                inline
                                type="switch"
                                id="custom-switch-dumbbell"
                                label="Dumbbell"
                                checked={dumbbell}
                                onChange={handleSwitchChangeDumbbell}
                            />
                            <Form.Check
                                inline
                                type="switch"
                                id="custom-switch-kettlebell"
                                label="Kettlebell"
                                checked={kettle}
                                onChange={handleSwitchChangeKettle}
                            />
                            <Form.Check
                                inline
                                type="switch"
                                id="custom-switch-pull"
                                label="Pull Up Bar"
                                checked={pull}
                                onChange={handleSwitchChangePull}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check
                                inline
                                type="checkbox"
                                id="custom-switch-upper"
                                label="Upper Body"
                                checked={upperBody}
                                onChange={handleSwitchChangeUpper}
                            />
                            <Form.Check
                                inline
                                type="checkbox"
                                id="custom-switch-lower"
                                label="Lower Body"
                                checked={lowerBody}
                                onChange={handleSwitchChangeLower}
                            />
                            <Form.Check
                                inline
                                type="checkbox"
                                id="custom-switch-full"
                                label="Full Body"
                                checked={fullBody}
                                onChange={handleSwitchChangeFull}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Difficulty Level</Form.Label>
                            <Form.Select name="difficulty" onChange={handleDiffInputChanged} value={difficulty}>
                                <option>Select a level...</option>
                                <option value="1">Easy</option>
                                <option value="2">Intermediate</option>
                                <option value="3">Hard</option>
                                <option value="4">Pro!</option>
                            </Form.Select>
                        </Form.Group>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            toolbar={{
                                options: ['inline', 'blockType', 'list']
                            }} />
                        <Button variant={buttonVarient} type="submit" onClick={handleButtonClicked} disabled={buttonState} >
                            {buttonText}
                        </Button>
                        <Button variant="danger" onClick={handleDeleteClicked} disabled={buttonState} className="delButton">
                            {buttonDelText}
                        </Button>
                    </Form>
                </div>
            </>
        )
    }
    return (
        <>
            <h1>Sorry, you need to be an admin user in order to access this page.</h1>
        </>
    )
}

export default withAuthenticator(ProductDetail);