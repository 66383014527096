import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Auth } from 'aws-amplify';
import './navbar.css';


function Nav_bar(user) {
  

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload(false);
  } catch (error) {
      console.log('error signing out: ', error);
  }
  }
    
    const [username, setUsername] = useState('');
    
    useEffect(() => {
        try {
          Auth.currentAuthenticatedUser({
            bypassCache: false
          }).then(user => {
            setUsername(user.username);
          }).catch(err => console.log(err));
        }
        catch (e) {
          console.log(e);
        }
      }, []);

    return (
        <Navbar>
            <Container>
                <Navbar.Brand href="/">Less Then Ten</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text className="loginTest">
                        Hey <span onClick={signOut} className="loginName">{username}</span>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Nav_bar;
