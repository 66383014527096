/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createWorkoutDetail = /* GraphQL */ `
  mutation CreateWorkoutDetail(
    $input: CreateWorkoutDetailInput!
    $condition: ModelWorkoutDetailConditionInput
  ) {
    createWorkoutDetail(input: $input, condition: $condition) {
      id
      workoutName
      containsDumbbell
      containsKettlebell
      containsPull
      workoutDetail
      uploadedBy
      lowerBody
      upperBody
      fullBody
      difficulty
      detail
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkoutDetail = /* GraphQL */ `
  mutation UpdateWorkoutDetail(
    $input: UpdateWorkoutDetailInput!
    $condition: ModelWorkoutDetailConditionInput
  ) {
    updateWorkoutDetail(input: $input, condition: $condition) {
      id
      workoutName
      containsDumbbell
      containsKettlebell
      containsPull
      workoutDetail
      uploadedBy
      lowerBody
      upperBody
      fullBody
      difficulty
      detail
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkoutDetail = /* GraphQL */ `
  mutation DeleteWorkoutDetail(
    $input: DeleteWorkoutDetailInput!
    $condition: ModelWorkoutDetailConditionInput
  ) {
    deleteWorkoutDetail(input: $input, condition: $condition) {
      id
      workoutName
      containsDumbbell
      containsKettlebell
      containsPull
      workoutDetail
      uploadedBy
      lowerBody
      upperBody
      fullBody
      difficulty
      detail
      createdAt
      updatedAt
    }
  }
`;
